@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$red:              #C3142D !default;
$text-color:       #222 !default;
$background-color: #FFFEFC !default;
$brand-color:      $red !default;
$link-color:       #007B89 !default;
$link-hover-color: darken($brand-color, 15%) !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;
$orange-color:     #f66a0a !default;
$table-text-align: left !default;

// Width of the content area
$bootstrap-sm:     576px !default;
$bootstrap-md:     768px !default;
$bootstrap-lg:     992px !default;
$bootstrap-xl:     1200px !default;

$content-width:    800px !default;
$on-palm:          600px !default;
$on-laptop:        800px !default;
$on-medium:        $on-palm !default;
$on-large:         $on-laptop !default;

// Customized h1 style for MU Libraries.
h1, h2, h3, h4, h5, h6,
.h1 .h2 .h3 .h4 .h5 .h6{
font-family: 'Cabin Condensed', sans-serif;
}
h1:after {
  background: -webkit-gradient(linear,left top,right top,from(#960018),to(#FF7883));
  background: linear-gradient(to right,#960018,#FF7883);
  border-radius: 2px;
  bottom: -0.2em;
  content: "";
  display: block;
  height: 0.1em;
  position: relative;
  width: 3.2em;
  margin-top: -0.15em;
}

h3, h4, h5, h6,
.h3 .h4 .h5 .h6{
  color: $grey-color-dark;
}

$list-group-active-bg:              #057AB8 !default;


// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
